/* =================================================================== */
/* Navy #495d7f
====================================================================== */

a, a:visited,
#not-found i,
.comment-by span.reply a:hover,
.comment-by span.reply a:hover i,
.categories a:hover,
.testimonials-author,
.happy-clients-author,
.dropcap,
.meta ul li a:hover,
.list-1 li:before,
.list-2 li:before,
.list-3 li:before,
.list-4 li:before { color: #495d7f; }

#current,
.menu ul li a:hover,
.menu ul > li:hover > a,
.flickr-widget-blog a:hover { border-color: #495d7f; }
.menu ul ul { border-top-color: #495d7f; }

.tp-leftarrow:hover,
.tp-rightarrow:hover,
.flexslider .flex-next:hover,
.flexslider .flex-prev:hover,
.featured-box:hover > .circle,
.featured-box:hover > .circle span,
.featured-box:hover > .circle-2,
.featured-box:hover > .circle-3,
.portfolio-item:hover > figure > a .item-description,
.sb-navigation-left:hover,
.sb-navigation-right:hover,
.newsletter-btn,
.search-btn { background-color: #495d7f; }

#filters a:hover, .selected { background-color: #495d7f !important; }

.premium .plan-price,
.premium .plan-features a.button:hover { background-color: #425473; }
.premium.plan h3,
.premium .plan-features a.button { background-color: #495d7f; }

.featured-box:hover > .circle-2,
.featured-box:hover > .circle-3 { box-shadow: 0 0 0 8px rgba(73, 93, 127, 0.3); }

#current:after,
.pagination .current,
.pagination ul li a:hover,
.tags a:hover,
.button.gray:hover,
.button.light:hover,
.button.color,
input[type="button"],
input[type="submit"],
input[type="button"]:focus,
input[type="submit"]:focus,
.tabs-nav li.active a,
.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active,
.trigger.active a,
.trigger.active a:hover,
.skill-bar-value,
.highlight.color,
.notice-box:hover { background: #495d7f; }